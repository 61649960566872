#kt_post {
  height: 100%;
}
#kt_content_container {
  //height: 100%;
  padding-bottom: 70px;
}
#kt_content_container > .card {
  height: 100%;
  overflow: auto;
}

//#kt_footer.footer {
//  position: fixed;
//  bottom: 0;
//}

.header .header-menu .menu > .menu-item > .menu-link > .menu-title {
  font-size: 1rem;
}

.MuiTablePagination-root p {
  margin-bottom: 0;
}
p.MuiTablePagination-displayedRows {
  margin-bottom: 0;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.text-dark .MuiButtonBase-root {
  color: var(--bs-text-dark) !important;
}

.col-form-label {
  color: var(--bs-gray-800) !important;
  padding-top: 3px;
  padding-bottom: 3px;
}

.MuiTypography-body1 {
  ul {
    li {
      &.ql-indent-1 {
        margin-left: 1rem;
      }
      &.ql-indent-2 {
        margin-left: 2rem;
      }
      &.ql-indent-3 {
        margin-left: 3rem;
      }
    }
  }
}

.bg-paper {
  padding: 12px;
  margin-bottom: 12px;
  background-color: #eeeeee !important;
  transition: background-color 0.3s !important;
  .card-body {
    background-color: #eeeeee !important;
    transition: background-color 0.3s !important;

    input, select, .MuiSelect-select {
      background-color: #ffffff !important;
    }
  }

  &:hover {
    background-color: #e0e0e0 !important;
    .card-body {
      background-color: #e0e0e0 !important;
    }
  }
}

.user-avatar {
  max-width: 200px;
  max-height: 200px;
  margin-right: 12px;
}
.image-item {
  display: flex;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.text-underline, .text-underline:hover {
  text-decoration: underline !important;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#kt_content_container > .card {
  overflow: visible;
}

.max-height-100 {
  max-height: 100px;
}

.comment-hr {
  margin: 4px 0;
}

.noselect, .noselect * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.custom-data-grid-header {
  font-weight: 500;
  line-height: 20px;
  hr {
    margin: 0;
  }
}

.header.bg-yellow {
  background-color: #d3a82f8a !important;
}

.MuiGrid2-container.rows {
  > .MuiGrid2-root {
    > .card {
      height: 100%;
      > .card-body > .collapse {
        height: 100%;
        > .form {
          height: 100%;
          > .card-body {
            height: 100%;
            .bg-gray {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
.bg-gray {
  background-color: #f8f9fa !important;
}
.bg-default {
  //background-color: #f8f9fa !important;
}
hr.tint {
  margin: 0;
}